import moment from "moment-timezone";
import { NeedsAttentionReasons } from "./types";

// Keep in sync with be util getDefaultContingency
export const DEFAULT_CONTINGENCY_CONFIG = {
	appeal_contingency: 25,
	appeal_flat: 0,
	exemption_primary_contingency: 25,
	exemption_primary_flat: 0,
};

const REAUTHORIZATION_NEEDS_ATTENTION_REASONS: NeedsAttentionReasons[] = [
	"Wrong Owner",
	"Authorization Expired",
];

const constants = {
	TEXAS_PHONE_NUMBER: 5128862282,
	WASHINGTON_PHONE_NUMBER: 2063958382,
	FLORIDA_PHONE_NUMBER: 3059012905,
	CALIFORNIA_PHONE_NUMBER: 3104210191,
	GEORGIA_PHONE_NUMBER: 6788900767,
	ILLINOIS_PHONE_NUMBER: 3125003131,
	NEW_YORK_PHONE_NUMBER: 5165183334,
	SPANISH_PHONE_NUMBER: 9563951220,
	FAX_PHONE_NUMBER: 5127661979,
	MATIC_PHONE_NUMBER: 8883183229,
	STATES_SERVED: ["TX", "WA", "CA", "FL", "GA", "IL", "NY"],
	STATES_SERVED_WITH_RETRO: ["TX", "NY", "IL", "MI"],
	BBB_LINK:
		"https://www.bbb.org/us/tx/austin/profile/property-tax-consultants/ownwell-0825-1000204347",
	GOOGLE_REVIEWS_LINK:
		"https://www.google.com/maps/place/Ownwell/@36.6993761,-102.41164,5z/data=!4m5!3m4!1s0x0:0xb689836bcb5ed574!8m2!3d36.6993761!4d-102.41164",
	CAREERS_EXTERNAL_LINK: "https://boards.greenhouse.io/ownwell",
	MAX_PASS_LENGTH: 100,
	OWNWELL_CONTACT_EMAIL: "hello@ownwell.com",
	REFERRAL_CREDIT: 20,
	DEFAULT_AUTOCOMPLETE_STATE: "TX",
	EVENTS: {
		LOGIN: "LOGIN",
		PORTAL_VISIT: "PORTAL_VISIT",
		PORTAL_PROPERTY_SURVEY_STARTED: "PORTAL_PROPERTY_SURVEY_STARTED",
		PORTAL_PROPERTY_SURVEY_UPDATED: "PORTAL_PROPERTY_SURVEY_UPDATED",
		PORTAL_PROPERTY_SURVEY_COMPLETED: "PORTAL_PROPERTY_SURVEY_COMPLETED",
		PORTAL_PROPERTY_SURVEY_BEGIN: "Prop Survey - Begin",
		PORTAL_PROPERTY_SURVEY_RECENT_PURCHASE: "Prop Survey - Recent Purchase",
		PORTAL_PROPERTY_SURVEY_RECENT_PURCHASE_INTERCEPT:
			"Prop Survey - Recent Purchase Intercept",
		PORTAL_PROPERTY_SURVEY_APPRAISAL_INSPECTION:
			"Prop Survey - Appraisal/Inspection",
		PORTAL_PROPERTY_SURVEY_PRIMARY_RESIDENCE: "Prop Survey - Primary Residence",
		PORTAL_PROPERTY_SURVEY_INCOME_PRODUCING: "Prop Survey - Income Producing",
		PORTAL_PROPERTY_SURVEY_NOTICE_OF_VALUE: "Prop Survey - Notice of Value",
		PORTAL_PROPERTY_SURVEY_DO_AND_DONT: "Prop Survey - Dos & Don'ts",
		PORTAL_PROPERTY_SURVEY_SURROUNDINGS: "Prop Survey - Surroundings",
		PORTAL_PROPERTY_SURVEY_FLOOD: "Prop Survey - Flood",
		PORTAL_PROPERTY_SURVEY_FOUNDATION: "Prop Survey - Foundation",
		PORTAL_PROPERTY_SURVEY_ROOF: "Prop Survey - Roof",
		PORTAL_PROPERTY_SURVEY_OTHER_DAMAGE: "Prop Survey - Other Damage",
		PORTAL_PROPERTY_SURVEY_VERIFY_DETAILS: "Prop Survey - Verify Details",
		PORTAL_PROPERT_SURVEY_CLOSE: "Prop Survey - Close Survey",
		PORTAL_PROPERTY_SURVEY_WILDCARD: "Prop Survey - Wild Card Questions",
		PORTAL_VISIT_CARD: "PORTAL_VISIT_CARD",
		PORTAL_VISIT_TABULAR: "PORTAL_VISIT_TABULAR",
		PORTAL_VISIT_TABULAR_EXPORT_CSV: "PORTAL_VISIT_TABULAR_EXPORT_CSV",
		PORTAL_VISIT_INVOICE: "PORTAL_VISIT_INVOICE",
		PORTAL_VISIT_DOCUMENT_UPLOAD: "PORTAL_VISIT_DOCUMENT_UPLOAD",
		PORTAL_VISIT_HELP_CENTER: "PORTAL_VISIT_HELP_CENTER",
		VIEW_PROPERTY_PAGE: "View Property Page",
		INS_START_SURVEY: "Ins - Start Survey",
		INS_ANSWER_MARITAL_STATUS: "Ins - Answer Marital Status",
		INS_ANSWER_DOB: "Ins - Answer DOB",
		INS_ANSWER_CREDIT_SCORE: "Ins - Answer Credit Score",
		INS_ANSWER_CURRENT_PROVIDER: "Ins - Answer Current Provider",
		INS_ANSWER_ANNUAL_PREMIUM: "Ins - Answer Annual Premium",
		INS_ANSWER_CURRENT_DEDUCTIBLE: "Ins - Answer Current Deductible",
		INS_JOIN_WAITLIST: "Ins - Joined Waitlist",
		INS_LEARN_MORE: "Ins - Learn More",
		INS_SURVEY_COMPLETE: "Ins - Survey Completed",
		INS_SURVEY_CLOSED: "Ins - Survey Closed",
		INS_REOPENED_SURVEY: "Ins - Reopened Survey",
		HOME_PAGE_VISIT: "HOME_PAGE_VISIT",
		FORM_FLOW_PROPERTY_ONLY_CONTACT_INFO:
			"FORM_FLOW_PROPERTY_ONLY_CONTACT_INFO",
		FORM_FLOW_ADDRESS_PARCEL: "FORM_FLOW_ADDRESS_PARCEL",
		FORM_FLOW_SAVINGS_ESTIMATE_SHOWN: "FORM_FLOW_SAVINGS_ESTIMATE_SHOWN",
		FORM_FLOW_SAVINGS: "FORM_FLOW_SAVINGS",
		FORM_FLOW_CONTACT_EMAIL_PHONE: "FORM_FLOW_CONTACT_EMAIL_PHONE",
		FORM_FLOW_CONTACT_EMAIL_PHONE_VIEWED:
			"FORM_FLOW_CONTACT_EMAIL_PHONE_VIEWED",
		FORM_FLOW_CONTACT_OWNED_BY_COMPANY: "FORM_FLOW_CONTACT_OWNED_BY_COMPANY",
		FORM_FLOW_CONTACT_NAME: "FORM_FLOW_CONTACT_NAME",
		FORM_FLOW_CONTACT_TAKEOVER: "FORM_FLOW_CONTACT_TAKEOVER",
		FORM_FLOW_CONTACT_PRIMARY_RESIDENCE: "FORM_FLOW_CONTACT_PRIMARY_RESIDENCE",
		FORM_FLOW_CONTACT_ACTIVE_FREEZE: "FORM_FLOW_CONTACT_ACTIVE_FREEZE",
		FORM_FLOW_CONTACT_AUTHORIZED_TO_SIGN:
			"FORM_FLOW_CONTACT_AUTHORIZED_TO_SIGN",
		FORM_FLOW_CONTACT_RECENT_PURCHASE: "FORM_FLOW_CONTACT_RECENT_PURCHASE",
		FORM_FLOW_CONTACT_PARCEL_NUMBER: "FORM_FLOW_CONTACT_PARCEL_NUMBER",
		FORM_FLOW_LEGAL: "FORM_FLOW_LEGAL",
		FORM_FLOW_ADDRESS: "FORM_FLOW_ADDRESS",
		FORM_FLOW_CONFIRMATION: "FORM_FLOW_CONFIRMATION",
		FORM_FLOW_VIEWED_APPEAL_SAVINGS_URL: "FORM_FLOW_VIEWED_APPEAL_SAVINGS_URL",
		HELP_CENTER_SEARCHED: "HELP_CENTER_SEARCHED",
		HELP_CENTER_CATEGORY_SELECTION: "HELP_CENTER_CATEGORY_SELECTION",
		HELP_CENTER_SECTION_SELECTION: "HELP_CENTER_SECTION_SELECTION",
		HELP_CENTER_ARTICLE_SELECTION: "HELP_CENTER_ARTICLE_SELECTION",
		HELP_CENTER_SUBMIT_REQUEST_BUTTON: "HELP_CENTER_SUBMIT_REQUEST_BUTTON",
		HELP_CENTER_SUBMIT_REQUEST_FROM_CATEGORY:
			"HELP_CENTER_SUBMIT_REQUEST_FROM_CATEGORY",
		HELP_CENTER_SUBMIT_REQUEST_FROM_SECTION:
			"HELP_CENTER_SUBMIT_REQUEST_FROM_SECTION",
		HELP_CENTER_SUBMIT_REQUEST_FROM_ARTICLE:
			"HELP_CENTER_SUBMIT_REQUEST_FROM_ARTICLE",
		HELP_CENTER_REQUEST_REC_ARTICLE: "HELP_CENTER_REQUEST_REC_ARTICLE",
		HELP_CENTER_REQUEST_SUG_ARTICLE: "HELP_CENTER_REQUEST_SUG_ARTICLE",
		HELP_CENTER_REQUEST_SUBMITTED: "HELP_CENTER_REQUEST_SUBMITTED",
		HELP_CENTER_CALL_US_FROM_HELP_CENTER:
			"HELP_CENTER_CALL_US_FROM_HELP_CENTER",
		HELP_CENTER_CALL_US_FROM_CATEGORY: "HELP_CENTER_CALL_US_FROM_CATEGORY",
		HELP_CENTER_CALL_US_FROM_SECTION: "HELP_CENTER_CALL_US_FROM_SECTION",
		HELP_CENTER_CALL_US_FROM_ARTICLE: "HELP_CENTER_CALL_US_FROM_ARTICLE",
		HELP_CENTER_SEARCHED_NO_RESULTS: "HELP_CENTER_SEARCHED_NO_RESULTS",
		ERROR_404_PAGE_VISIT: "404_ERROR_PAGE_VISIT",
		Commerical_GetStarted_Button: "Commerical_GetStarted_Button",
		Commerical_GetStarted_NumOfPropsModal:
			"Commerical_GetStarted_NumOfPropsModal",
		Savings_Page_Parcel_Number: "Savings_Page_Parcel_Number",
		Document_Downloaded: "Document_Downloaded",
		Document_Download_Failed: "Document_Download_Failed",
		Invoices_Field_Validation_Issue: "Invoices_Field_Validation_Issue",
		Invoices_Card_Failed: "Invoices_Card_Failed",
		Invoice_viewed: "Invoice_viewed",
		Invoice_paid: "Invoice_paid",
		Appeal_History_Article: "Appeal_History_Article",
		Appeal_History_WhatDoesThisMean: "Appeal_History_WhatDoesThisMean",
		Appeal_History_ViewAllHistory: "Appeal_History_ViewAllHistory",
		Exemptions_Property_Page_Start_CTA: "Exemptions_Property_Page_Start_CTA",
		Exemptions_Flow_Intro_Page_View: "Exemptions_Flow_Intro_Page_View",
		Exemptions_Flow_Intro_Page_Next_Button:
			"Exemptions_Flow_Intro_Page_Next_Button",
		Exemptions_Flow_Primary_Residence_View:
			"Exemptions_Flow_Primary_Residence_View",
		Exemptions_Flow_Primary_Residence_Answer:
			"Exemptions_Flow_Primary_Residence_Answer",
		Exemptions_Flow_Current_Homestead_View:
			"Exemptions_Flow_Current_Homestead_View",
		Exemptions_Flow_Current_Homestead_Answer:
			"Exemptions_Flow_Current_Homestead_Answer",
		Exemptions_Flow_Transfer_Question_View:
			"Exemptions_Flow_Transfer_Question_View",
		Exemptions_Flow_Transfer_Question_Answer:
			"Exemptions_Flow_Transfer_Question_Answer",
		Exemptions_Flow_Previous_Residence_View:
			"Exemptions_Flow_Previous_Residence_View",
		Exemptions_Flow_Previous_Residence_Answer:
			"Exemptions_Flow_Previous_Residence_Answer",
		Exemptions_Flow_Estimates_No_Refund_View:
			"Exemptions_Flow_Estimates_No_Refund_View",
		Exemptions_Flow_Estimates_No_Refund_Next_Button:
			"Exemptions_Flow_Estimates_No_Refund_Next_Button",
		Exemptions_Flow_Estimates_No_Refund_Explainer_Button:
			"Exemptions_Flow_Estimates_No_Refund_Explainer_Button",
		Exemptions_Flow_Estimates_Refund_View:
			"Exemptions_Flow_Estimates_Refund_View",
		Exemptions_Flow_Estimates_Refund_Next_Button:
			"Exemptions_Flow_Estimates_Refund_Next_Button",
		Exemptions_Flow_Estimates_Refund_Explainer_Button:
			"Exemptions_Flow_Estimates_Refund_Explainer_Button",
		Exemptions_Flow_DOB_Viewed: "Exemptions_Flow_DOB_Viewed",
		Exemptions_Flow_DOB_Answer: "Exemptions_Flow_DOB_Answer",
		Exemptions_Flow_Annual_Household_Income_View:
			"Exemptions_Flow_Annual_Household_Income_View",
		Exemptions_Flow_Annual_Household_Income_Answer:
			"Exemptions_Flow_Annual_Household_Income_Answer",
		Exemptions_Flow_Tax_Filing_History_Answer:
			"Exemptions_Flow_Tax_Filing_History_Answer",
		Exemptions_Flow_Tax_Filing_History_View:
			"Exemptions_Flow_Tax_Filing_History_View",
		Exemptions_Flow_Tax_Filing_History_2_Answer:
			"Exemptions_Flow_Tax_Filing_History_2_Answer",
		Exemptions_Flow_Tax_Filing_History_2_View:
			"Exemptions_Flow_Tax_Filing_History_2_View",
		Exemptions_Flow_Tax_Filing_History_Details_Answer:
			"Exemptions_Flow_Tax_Filing_History_Details_Answer",
		Exemptions_Flow_Tax_Filing_History_Details_View:
			"Exemptions_Flow_Tax_Filing_History_Details_View",
		Exemptions_Flow_Tax_Filing_History_Details_2_Answer:
			"Exemptions_Flow_Tax_Filing_History_Details_2_Answer",
		Exemptions_Flow_Tax_Filing_History_Details_2_View:
			"Exemptions_Flow_Tax_Filing_History_Details_2_View",
		Exemptions_Flow_Transfer_Star_Credit_Answer:
			"Exemptions_Flow_Transfer_Star_Credit_Answer",
		Exemptions_Flow_Transfer_Star_Credit_View:
			"Exemptions_Flow_Transfer_Star_Credit_View",
		Exemptions_Flow_Trust_Document_Upload_Answer:
			"Exemptions_Flow_Trust_Document_Upload_Answer",
		Exemptions_Flow_Trust_Document_Upload_View:
			"Exemptions_Flow_Trust_Document_Upload_View",
		Exemptions_Flow_Other_Situations_Viewed:
			"Exemptions_Flow_Other_Situations_Viewed",
		Exemptions_Flow_Other_Situations_Answer:
			"Exemptions_Flow_Other_Situations_Answer",
		Exemptions_Flow_Income_Producing_View:
			"Exemptions_Flow_Income_Producing_%_View",
		Exemptions_Flow_Income_Producting_Answer:
			"Exemptions_Flow_Income_Producting_%_Answer",
		Exemptions_Flow_Ownership_View: "Exemptions_Flow_Ownership_View",
		Exemptions_Flow_Ownership_Answer: "Exemptions_Flow_Ownership_Answer",
		Exemptions_Flow_Marital_Status_View: "Exemptions_Flow_Marital_Status_View",
		Exemptions_Flow_Marital_Status_Answer:
			"Exemptions_Flow_Marital_Status_Answer",
		Exemptions_Flow_Additional_Owner_View:
			"Exemptions_Flow_Additional_Owner_View",
		Exemptions_Flow_Additional_Owner_Answer:
			"Exemptions_Flow_Additional_Owner_Answer",
		Exemptions_Flow_Ownership_Percentage_View:
			"Exemptions_Flow_Ownership_Percentage_View",
		Exemptions_Flow_Ownership_Percentage_Answer:
			"Exemptions_Flow_Ownership_Percentage_Answer",
		Exemptions_Flow_Drivers_License_1_View:
			"Exemptions_Flow_Drivers_License_1_View",
		Exemptions_Flow_Drivers_License_1_Answer:
			"Exemptions_Flow_Drivers_License_1_Answer",
		Exemptions_Flow_Drivers_License_2_View:
			"Exemptions_Flow_Drivers_License_2_View",
		Exemptions_Flow_SSN_TIN_View: "Exemptions_Flow_SSN_TIN_View",
		Exemptions_Flow_SSN_TIN_Answer: "Exemptions_Flow_SSN_TIN_Answer",
		Exemptions_Flow_SSN_TIN2_View: "Exemptions_Flow_SSN_TIN2_View",
		Exemptions_Flow_SSN_TIN2_Answer: "Exemptions_Flow_SSN_TIN2_Answer",
		Exemptions_Flow_DRIVERS_LICENSE_NUMBER_View:
			"Exemptions_Flow_DRIVERS_LICENSE_NUMBER_View",
		Exemptions_Flow_DRIVERS_LICENSE_NUMBER_Answer:
			"Exemptions_Flow_DRIVERS_LICENSE_NUMBER_Answer",
		Exemptions_Flow_DRIVERS_LICENSE_NUMBER2_View:
			"Exemptions_Flow_DRIVERS_LICENSE_NUMBER2_View",
		Exemptions_Flow_DRIVERS_LICENSE_NUMBER2_Answer:
			"Exemptions_Flow_DRIVERS_LICENSE_NUMBER2_Answer",
		Exemptions_Flow_VEHICLE_REGISTRATIONS_View:
			"Exemptions_Flow_VEHICLE_REGISTRATIONS_View",
		Exemptions_Flow_VEHICLE_REGISTRATIONS_Answer:
			"Exemptions_Flow_VEHICLE_REGISTRATIONS_Answer",
		Exemptions_Flow_VEHICLE_INFO_Answer: "Exemptions_Flow_VEHICLE_INFO_Answer",
		Exemptions_Flow_Drivers_License_2_Answer:
			"Exemptions_Flow_Drivers_License_2_Answer",
		Exemptions_Flow_Other_Property_Counties_View:
			"Exemptions_Flow_Other_Property_Counties_View",
		Exemptions_Flow_Other_Property_Counties_Answer:
			"Exemptions_Flow_Other_Property_Counties_Answer",
		Exemptions_Flow_Have_Solar_View: "Exemptions_Flow_Have_Solar?_View",
		Exemptions_Flow_Have_Solar_Answer: "Exemptions_Flow_Have_Solar?_Answer",
		Exemptions_Flow_Have_Solar_Exemptions_View:
			"Exemptions_Flow_Have_Solar_Exemptions?_View",
		Exemptions_Flow_Have_Solar_Exemptions_Answer:
			"Exemptions_Flow_Have_Solar_Exemptions?_Answer",
		Exemptions_Flow_Solar_Interest_View: "Exemptions_Flow_Solar_Interest_View",
		Exemptions_Flow_Solar_Interest_Answer:
			"Exemptions_Flow_Solar_Interest_Answer",
		Exemptions_Flow_Legal_Page_View: "Exemptions_Flow_Legal_Page_View",
		Exemptions_Flow_Legal_Page_Answer: "Exemptions_Flow_Legal_Page_Answer",
		Exemptions_Flow_Agreement_Confirmation_View:
			"Exemptions_Flow_Agreement_Confirmation_View",
		Exemptions_Flow_NonAgreement_Confirmation_View:
			"Exemptions_Flow_Non-Agreement_Confirmation_View",
		Exemptions_Flow_Manufactured_Home_Page_View:
			"Exemptions_Flow_Manufactured_Home_Page_View",
		Exemptions_Flow_Manufactured_Home_Page_Answer:
			"Exemptions_Flow_Manufactured_Home_Page_Answer",
		Exemptions_Flow_Manufactured_Home_Page_Skipped:
			"Exemptions_Flow_Manufactured_Home_Page_Skipped",
		Tax_Page_Taxable_Values: "Tax_Page_Taxable_Values",
		Tax_Page_Tax_Bill: "Tax_Page_Tax_Bill",
		Feedback_TaxDash_Bill_Useful_Comment:
			"Feedback_TaxDash_Bill_Useful_Comment",
		Feedback_TaxDash_Bill_Not_Useful_Comment:
			"Feedback_TaxDash_Bill_Not_Useful_Comment",
		Feedback_Equity_Card: "Feedback_Equity_Card",
		Feedback_Equity_Card_Useful_Comment: "Feedback_Equity_Card_Useful_Comment",
		Feedback_Equity_Card_Not_Useful_Comment:
			"Feedback_Equity_Card_Not_Useful_Comment",
		Exemptions_Flow_Transfer_Move_Sell_Dates_View:
			"Exemptions_Flow_Transfer_Move/Sell_Dates_View",
		Exemptions_Flow_Transfer_Move_Sell_Dates_Action:
			"Exemptions_Flow_Transfer_Move/Sell_Dates_Action",
		Refer_From_Settings_Button: "Refer_From_Settings_Button",
		Refer_From_Property_Button: "Refer_From_Property_Button",
		Refer_Page_Share_Link_Button: "Refer_Page_Share_Link_Button",
		Refer_Page_Share_Facebook_Button: "Refer_Page_Share_Facebook_Button",
		Refer_Page_Share_LinkedIn_Button: "Refer_Page_Share_LinkedIn_Button",
		Refer_Page_Share_Twitter_Button: "Refer_Page_Share_Twitter_Button",
		Refer_Page_Share_Email_Button: "Refer_Page_Share_Email_Button",
		Refer_Page_Share_SMS_Button: "Refer_Page_Share_SMS_Button",
		Referral_Give20Get20_Button: "Referral_Give20Get20_Button",
		Exemptions_Flow_MoveInDate_View: "Exemptions_Flow_MoveInDate_View",
		Exemptions_Flow_MoveInDate_Answer: "Exemptions_Flow_MoveInDate_Answer",
		Exemptions_Flow_PurchaseDate_View: "Exemptions_Flow_PurchaseDate_View",
		Exemptions_Flow_PurchaseDate_Answer: "Exemptions_Flow_PurchaseDate_Answer",
		Tax_Dash_Taxes_Nav_Button: "Tax_Dash_Taxes_Nav_Button",
		E2E_Exemptions_LP_CTAs: "E2E_Exemptions_LP_CTAs",
		E2E_Exemptions_LP: "E2E_Exemptions_LP",
		E2E_Exemptions_Address_Page_Viewed: "E2E_Exemptions_Address_Page_Viewed",
		E2E_Exemptions_Address_Page_Entry_ResultsSuccess:
			"E2E_Exemptions_Address_Page_Entry_ResultsSuccess",
		E2E_Exemptions_Address_Page_Entry_ResultsManual_Entry:
			"E2E_Exemptions_Address_Page_Entry_ResultsManual_Entry",
		E2E_Exemptions_Primary_Address_View: "E2E_Exemptions_Primary_Address_View",
		E2E_Exemptions_Primary_Address_Answer:
			"E2E_Exemptions_Primary_Address_Answer",
		E2E_Exemptions_Current_Exemption_View:
			"E2E_Exemptions_Current_Exemption_View",
		E2E_Exemptions_Current_Exemption_Answer:
			"E2E_Exemptions_Current_Exemption_Answer",
		E2E_Exemptions_Move_In_Date_View: "E2E_Exemptions_Move_In_Date_View",
		E2E_Exemptions_Move_In_Date_Answer: "E2E_Exemptions_Move_In_Date_Answer",
		E2E_Exemptions_Purchase_Date_View: "E2E_Exemptions_Purchase_Date_View",
		E2E_Exemptions_Purchase_Date_Answer: "E2E_Exemptions_Purchase_Date_Answer",
		E2E_Exemptions_Not_Servicing_Address:
			"E2E_Exemptions_Not_Servicing_Address",
		E2E_Exemptions_Savings_Estimates_View:
			"E2E_Exemptions_Savings_Estimates_View",
		E2E_Exemptions_Contact_Info_View: "E2E_Exemptions_Contact_Info_View",
		E2E_Exemptions_LP_Address_Entry_Auto_Complete:
			"E2E_Exemptions_LP_Address_Entry_Auto_Complete",
		E2E_Exemptions_LP_Address_Entry_DontSeeAddress:
			"E2E_Exemptions_LP_Address_Entry_DontSeeAddress",
		E2E_Exemptions_LP_Address_Entry_Non_Auto_Complete:
			"E2E_Exemptions_LP_Address_Entry_Non_Auto_Complete",
		E2E_Exemptions_Owner_Details_View: "E2E_Exemptions_Owner_Details_View",
		E2E_Exemptions_TrustLLC_View: "E2E_Exemptions_Trust/LLC_View",
		E2E_Exemptions_Agreement_Page_View: "E2E_Exemptions_Agreement_Page_View",
		E2E_Exemptions_Confirm_Email_View: "E2E_Exemptions_Confirm_Email_View",
		E2E_Exemptions_Welcome_Page_View: "E2E_Exemptions_Welcome_Page_View",
		E2E_Exemptions_Password_Creation_View:
			"E2E_Exemptions_Password_Creation_View",
		Exemptions_Flow_Drivers_License_OCR_Success:
			"Exemptions_Flow_Drivers_License_OCR_Success",
		Exemptions_Flow_Drivers_License_OCR_Failed:
			"Exemptions_Flow_Drivers_License_OCR_Failed",
		Exemptions_Flow_Already_Filed_View: "Exemptions_Flow_Already_Filed_View",
		Exemptions_Flow_Already_Filed_Answer:
			"Exemptions_Flow_Already_Filed_Answer",
		Notification_Announcement_Exemption_Continue:
			"Notification_Announcement_Exemption_Continue",
		Notification_Announcement_Exemption_Dismiss:
			"Notification_Announcement_Exemption_Dismiss",
		Notification_Announcement_Tax_Dashboard_Continue:
			"Notification_Announcement_Tax_Dashboard_Continue",
		Notification_Announcement_Tax_Dashboard_Dismiss:
			"Notification_Announcement_Tax_Dashboard_Dismiss",
		Notification_Announcement_Unlock_Premium_Continue:
			"Notification_Announcement_Unlock_Premium_Continue",
		Notification_Announcement_Unlock_Premium_Dismiss:
			"Notification_Announcement_Unlock_Premium_Dismiss",
		Main_Landing_Page_Address_Entry_DontSeeProperty:
			"Main_Landing_Page_Address_Entry_Don'tSeeProperty",
		Main_Landing_Page_Address_Entry_ResultsSuccess:
			"Main_Landing_Page_Address_Entry_ResultsSuccess",
		Appeal_Specific_Address_Entry_DontSeeProperty:
			"Appeal_Specific_Address_Entry_Don'tSeeProperty",
		Appeal_Specific_Address_Entry_ResultsSucess:
			"Appeal_Specific_Address_Entry_ResultsSucess",
		Portfolio_Dashboard_Page_Load: "Portfolio_Dashboard_Page_Load",
		Portfolio_Dashboard_Action_Required_Filter:
			"Portfolio_Dashboard_Action_Required_Filter",
		Portfolio_Dashboard_Property_Search: "Portfolio_Dashboard_Property_Search",
		Portfolio_Dashboard_State_Filter: "Portfolio_Dashboard_State_Filter",
		Portfolio_Dashboard_Entity_Filter: "Portfolio_Dashboard_Entity_Filter",
		Portfolio_Dashboard_Status_Appeal_Filter:
			"Portfolio_Dashboard_Status_Appeal_Filter",
		Premium_Upgrade_Before_Legal_Page: "Premium_Upgrade_Before_Legal_Page",
		Premium_Upgrade_Modal_Viewed: "Premium_Upgrade_Modal_Viewed",
		Premium_Upgrade_Essentials_Button: "Premium_Upgrade_Essentials_Button",
		Premium_Upgrade_Upgrade_Button: "Premium_Upgrade_Upgrade_Button",
		Premium_Upgrade_Investors_Button: "Premium_Upgrade_Investors_Button",
		Premium_Benefits_Button: "Premium_Benefits_Button",
		Equity_Get_Started_Button: "Equity_Get_Started_Button",
		Equity_New_Data_Saved: "Equity_New_Data_Saved",
		Equity_Negative_Equity: "Equity_Negative_Equity",
		Leftovers_Portal_Select_All: "Leftovers_Portal_Selcet_All",
		Leftovers_Portal_Add_Properties: "Leftovers_Portal_Add_Properties",
		Leftovers_Portal_Authorize: "Leftovers_Portal_Authorize",
		Feedback_Expenses: "Feedback_Expenses",
		Feedback_Expenses_Useful_Comment: "Feedback_Expenses_Useful_Comment",
		Feedback_Expenses_Not_Useful_Comment:
			"Feedback_Expenses_Not_Useful_Comment",
		Expenses_CTA_Compare: "Expenses_CTA_Compare",
		Expenses_Results_Viewed: "Expenses_Results_Viewed",
		Form_Flow_Verify_Email_Viewed: "Form_Flow_Verify_Email_Viewed",
		Form_Flow_Verify_Email_Continue: "Form_Flow_Verify_Email_Continue",
		Form_Flow_Welcome_Confirmation_Viewed:
			"Form_Flow_Welcome_Confirmation_Viewed",
		Form_Flow_Welcome_Confirmation_Continue:
			"Form_Flow_Welcome_Confirmation_Continue",
		Form_Flow_Welcome_Confirmation_Add_Property:
			"Form_Flow_Welcome_Confirmation_Add_Property",
		Form_Flow_Password_Page_Viewed: "Form_Flow_Password_Page_Viewed",
		Form_Flow_Password_Page_Set_Password:
			"Form_Flow_Password_Page_Set_Password",
		W2S_More_Ways_To_Save_CTA: "W2S_More_Ways_To_Save_CTA",
		W2S_More_Ways_To_Save_Cash_Back: "W2S_More_Ways_To_Save_Cash_Back",
		W2S_More_Ways_To_Save_Buying_Or_Selling_A_Property:
			"W2S_More_Ways_To_Save_Buying_Or_Selling_A_Property",
		W2S_More_Ways_To_Save_Other_Ways: "W2S_More_Ways_To_Save_Other_Ways",
		W2S_Exemption_Start_CTA: "W2S_Exemption_Start_CTA",
		W2S_Insurance_Tab_Activate_CTA_Clicked:
			"W2S_Insurance_Tab_Activate_CTA_Clicked",
		W2S_Tab_Clicked: "W2S_Tab_Clicked",
		W2S_Bill_Reduction_Bill_Picker_Clicked:
			"W2S_Bill_Reduction_Bill_Picker_Clicked",
		W2S_Bill_Reduction_Bill_Modal_Viewed:
			"W2S_Bill_Reduction_Bill_Modal_Viewed",
		W2S_Turn_On_Mortgage_Monitoring: "W2S_Turn_On_Mortgage_Monitoring",
		W2S_Bill_Reduction_Provider_Entered: "W2S_Bill_Reduction_Provider_Entered",
		W2S_Tax_Appeal_Tab_Resolve_NAR_CTA: "W2S_Tax_Appeal_Tab_Resolve_NAR_CTA",
		W2S_Exemption_Upload_Doc_NAR_CTA: "W2S_Exemption_Upload_Doc_NAR _CTA",
		W2S_Bill_Reduction_Bill_Submitted: "W2S_Bill_Reduction_Bill_Submitted",
		W2S_Bill_Reduction_Bill_Modal_Closed:
			"W2S_Bill_Reduction_Bill_Modal_Closed",
		W2S_Insurance_Tab_UpdateInfo_Clicked:
			"W2S_Insurance_Tab_UpdateInfo_Clicked",
		W2S_save_mortgage_monitoring: "W2S_save_mortgage_monitoring",
		W2S_update_mortgage_info: "W2S_update_mortgage_info",
		W2S_mortgage_equity_tab: "W2S_mortgage_equity_tab",
		W2S_moreW2S_CTA: "W2S_moreW2S_CTA",
		W2S_moreW2S_submit: "W2S_moreW2S_submit",
		W2S_Exemptions_Not_Supported_But_Interested:
			"W2S_Exemptions_Not_Supported_But_Interested",
		W2S_Appeals_Not_Supported_But_Interested:
			"W2S_Appeals_Not_Supported_But_Interested",
		Expenses_Mortgage_Details_View: "Expenses_Mortgage_Details_View",
		Expenses_Mortgage_Details_Next: "Expenses_Mortgage_Details_Next",
		Expenses_Monthly_Expenses_View: "Expenses_Monthly_Expenses_View",
		Expenses_Monthly_Expenses_Next: "Expenses_Monthly_Expenses_Next",
		Expenses_Utilities_View: "Expenses_Utilities_View",
		Expenses_Utilities_Next: "Expenses_Utilities_Next",
		Form_Flow_Legal_Page_Viewed: "Form_Flow_Legal_Page_Viewed",
		Form_Flow_Contact_Name_Entity_Viewed:
			"Form_Flow_Contact_Name_Entity_Viewed",
		Partner_page_visit: "Partner_page_visit",
		Partner_page_signup_start: "Partner_page_signup_start",
		Partner_page_signup_complete: "Partner_page_signup_complete",
		Form_Flow_address_entry_viewed: "Form_Flow_address_entry_viewed",
		Investor_page_contact_us: "Investor_page_contact_us",
		Investor_page_address_page: "Investor_page_address_page",
		Investor_page_add_properties: "Investor_page_add_properties",
		Investor_page_email_phone: "Investor_page_email_phone",
		Investor_page_name_page: "Investor_page_name_page",
		Investor_page_name_page_entity: "Investor_page_name_page_entity",
		Investor_page_trust_llc_page: "Investor_page_trust_llc_page",
		Investor_page_magic_link: "Investor_page_magic_link",
		Investor_page_confirmation: "Investor_page_confirmation",
		Insurance_LP_Viewed: "Insurance_LP_Viewed",
		Leftovers_modal_view: "Leftovers_modal_view",
		Leftovers_modal_add_property: "Leftovers_modal_add_property",
		Referral_modal_join_partner_click: "Referral_modal_join_partner_click",
		Onboarding_welcome_page_viewed: "Onboarding_welcome_page_viewed",
		Onboarding_welcome_page_begin_clicked:
			"Onboarding_welcome_page_begin_clicked",
		Onboarding_property_survey_page_viewed:
			"Onboarding_property_survey_page_viewed",
		Onboarding_property_survey_begin_clicked:
			"Onboarding_property_survey_begin_clicked",
		Onboarding_property_survey_exit: "Onboarding_property_survey_exit",
		Onboarding_property_survey_complete_view:
			"Onboarding_property_survey_complete_view",
		Onboarding_property_survey_complete_exemptions:
			"Onboarding_property_survey_complete_exemptions",
		Onboarding_property_survey_complete_insurance:
			"Onboarding_property_survey_complete_insurance",
		Onboarding_property_survey_complete_bill_reduction:
			"Onboarding_property_survey_complete_bill_reduction",
		Onboarding_property_survey_complete_mortgage_equity:
			"Onboarding_property_survey_complete_mortgage_equity",
		Onboarding_property_survey_complete_exit:
			"Onboarding_property_survey_complete_exit",
		Onboarding_property_survey_complete_done:
			"Onboarding_property_survey_complete_done",
		Onboarding_exemptions_viewed: "Onboarding_exemptions_viewed",
		Onboarding_mortgage_equity_viewed: "Onboarding_mortgage_equity_viewed",
		Onboarding_insurance_viewed: "Onboarding_insurance_viewed",
		Onboarding_bill_reduction_viewed: "Onboarding_bill_reduction_viewed",
		Onboarding_exemptions_start: "Onboarding_exemptions_start",
		Onboarding_mortgage_equity_start: "Onboarding_mortgage_equity_start",
		Onboarding_insurance_start: "Onboarding_insurance_start",
		Onboarding_bill_reduction_start: "Onboarding_bill_reduction_start",
		Onboarding_exemptions_exit: "Onboarding_exemptions_exit",
		Onboarding_mortgage_equity_exit: "Onboarding_mortgage_equity_exit",
		Onboarding_insurance_exit: "Onboarding_insurance_exit",
		Onboarding_bill_reduction_exit: "Onboarding_bill_reduction_exit",
		Onboarding_exemptions_skip: "Onboarding_exemptions_skip",
		Onboarding_mortgage_equity_skip: "Onboarding_mortgage_equity_skip",
		Onboarding_insurance_skip: "Onboarding_insurance_skip",
		Onboarding_finish_tour: "Onboarding_finish_tour",
		File_Upload_Failed: "File_Upload_Failed",
		Mortgage_tab_feedback: "Mortgage_tab_feedback",
		Mortgage_tab_feedback_thumbs_up_comments:
			"Mortgage_tab_feedback_thumbs_up_comments",
		Mortgage_tab_feedback_thumbs_down_comments:
			"Mortgage_tab_feedback_thumbs_down_comments",
		County_Trends_Tab_View: "County_Trends_Tab_View",
		Neighborhood_Tab_View: "Neighborhood_Tab_View",
		Neighborhood_Tab_Table_Click: "Neighborhood_Tab_Table_Click",
		Neighborhood_Tab_Map_Pin_Click: "Neighborhood_Tab_Map_Pin_Click",
		Neighborhood_Tab_No_Data: "Neighborhood_Tab_No_Data",
		Crime_Available: "crime_available",
		W2S_BillReduc_ElectricBill_Tab_Viewed:
			"W2S_BillReduc_ElectricBill_Tab_Viewed",
		W2S_BillReduc_Electric_Tab_Providers_Clicked:
			"W2S_BillReduc_Electric_Tab_Providers_Clicked",
		W2S_BillReduc_Electric_Tab_Providers_Chosen:
			"W2S_BillReduc_Electric_Tab_Providers_Chosen",
		W2S_BillReduc_Electric_Tab_Uploaded_File:
			"W2S_BillReduc_Electric_Tab_Uploaded_File",
		W2S_BillReduc_Electric_Tab_Submit_CTA:
			"W2S_BillReduc_Electric_Tab_Submit_CTA",
		W2S_BillReduc_ElectricBill_Tab_Start_CTA:
			"W2S_BillReduc_ElectricBill_Tab_Start_CTA",
		W2S_BillReduc_Internet_Tab_Viewed: "W2S_BillReduc_Internet_Tab_Viewed",
		W2S_BillReduc_Internet_Tab_Start_CTA:
			"W2S_BillReduc_Internet_Tab_Start_CTA",
		W2S_BillReduc_Internet_Tab_Providers_Clicked:
			"W2S_BillReduc_Internet_Tab_Providers_Clicked",
		W2S_BillReduc_Internet_Tab_Providers_Chosen:
			"W2S_BillReduc_Internet_Tab_Providers_Chosen",
		W2S_BillReduc_Internet_Tab_Uploaded_File:
			"W2S_BillReduc_Internet_Tab_Uploaded_File",
		W2S_BillReduc_Internet_Tab_Submit_CTA:
			"W2S_BillReduc_Internet_Tab_Submit_CTA",
		W2S_BillReduc_Phone_Tab_Viewed: "W2S_BillReduc_Phone_Tab_Viewed",
		W2S_BillReduc_CableSatellite_Tab_Viewed:
			"W2S_BillReduc_CableSatellite_Tab_Viewed",
		W2S_BillReduc_Security_Tab_Viewed: "W2S_BillReduc_Security_Tab_Viewed",
		W2S_BillReduc_HomePhone_Tab_Viewed: "W2S_BillReduc_HomePhone_Tab_Viewed",
		W2S_BillReduc_Other_Tab_Viewed: "W2S_BillReduc_Other_Tab_Viewed",
		W2S_BillReduc_Phone_Tab_Start_CTA: "W2S_BillReduc_Phone_Tab_Start_CTA",
		W2S_BillReduc_Phone_Tab_Providers_Clicked:
			"W2S_BillReduc_Phone_Tab_Providers_Clicked",
		W2S_BillReduc_Phone_Tab_Providers_Chosen:
			"W2S_BillReduc_Phone_Tab_Providers_Chosen",
		W2S_BillReduc_Phone_Tab_Uploaded_File:
			"W2S_BillReduc_Phone_Tab_Uploaded_File",
		W2S_BillReduc_Phone_Tab_Submit_CTA: "W2S_BillReduc_Phone_Tab_Submit_CTA",
		W2S_BillReduc_CableSatellite_Tab_Providers_Clicked:
			"W2S_BillReduc_CableSatellite_Tab_Providers_Clicked",
		W2S_BillReduc_CableSatellite_Tab_Providers_Chosen:
			"W2S_BillReduc_CableSatellite_Tab_Providers_Chosen",
		W2S_BillReduc_CableSatellite_Tab_Uploaded_File:
			"W2S_BillReduc_CableSatellite_Tab_Uploaded_File",
		W2S_BillReduc_CableSatellite_Tab_Submit_CTA:
			"W2S_BillReduc_CableSatellite_Tab_Submit_CTA",
		W2S_BillReduc_HomePhone_Tab_Providers_Clicked:
			"W2S_BillReduc_HomePhone_Tab_Providers_Clicked",
		W2S_BillReduc_HomePhone_Tab_Providers_Chosen:
			"W2S_BillReduc_HomePhone_Tab_Providers_Chosen",
		W2S_BillReduc_HomePhone_Tab_Uploaded_File:
			"W2S_BillReduc_HomePhone_Tab_Uploaded_File",
		W2S_BillReduc_HomePhone_Tab_Submit_CTA:
			"W2S_BillReduc_HomePhone_Tab_Submit_CTA",
		W2S_BillReduc_Security_Tab_Providers_Clicked:
			"W2S_BillReduc_Security_Tab_Providers_Clicked",
		W2S_BillReduc_Security_Tab_Providers_Chosen:
			"W2S_BillReduc_Security_Tab_Providers_Chosen",
		W2S_BillReduc_Security_Tab_Uploaded_File:
			"W2S_BillReduc_Security_Tab_Uploaded_File",
		W2S_BillReduc_Security_Tab_Submit_CTA:
			"W2S_BillReduc_Security_Tab_Submit_CTA",
		W2S_BillReduc_Other_Tab_Providers_Clicked:
			"W2S_BillReduc_Other_Tab_Providers_Clicked",
		W2S_BillReduc_Other_Tab_Providers_Chosen:
			"W2S_BillReduc_Other_Tab_Providers_Chosen",
		W2S_BillReduc_Other_Tab_Uploaded_File:
			"W2S_BillReduc_Other_Tab_Uploaded_File",
		W2S_BillReduc_Other_Tab_Submit_CTA: "W2S_BillReduc_Other_Tab_Submit_CTA",
		W2S_BillReduc_CableSatellite_Tab_Start_CTA:
			"W2S_BillReduc_CableSatellite_Tab_Start_CTA",
		W2S_BillReduc_Security_Tab_Start_CTA:
			"W2S_BillReduc_Security_Tab_Start_CTA",
		W2S_BillReduc_HomePhone_Tab_Start_CTA:
			"W2S_BillReduc_HomePhone_Tab_Start_CTA",
		W2S_BillReduc_Other_Tab_Start_CTA: "W2S_BillReduc_Other_Tab_Start_CTA",
		Taxes_Tab_Viewed: "Taxes_Tab_Viewed",
		Mortgage_Tab_Viewed: "Mortgage_Tab_Viewed",
		Expenses_Tab_Viewed: "Expenses_Tab_Viewed",
		Documents_Tab_Viewed: "Documents_Tab_Viewed",
		W2S_Insurance_Deals_Available: "W2S_Insurance_Deals_Available",
		W2S_Insurance_Show_Deal_Details: "W2S_Insurance_Show_Deal_Details",
		W2S_Insurance_Deal_Interested_Clicked:
			"W2S_Insurance_Deal_Interested_Clicked",
		W2S_Insurance_Deal_Call_Me_Clicked: "W2S_Insurance_Deal_Call_Me_Clicked",
		W2S_Insurance_Deal_Call_Me_Modal_Closed:
			"W2S_Insurance_Deal_Call_Me_Modal_Closed",
		W2S_Insurance_Deal_Expect_Call_Modal_Viewed:
			"W2S_Insurance_Deal_Expect_Call_Modal_Viewed",
		RoiCalculator_Feedback: "RoiCalculator_Feedback",
		RoiCalculator_Feedback_Thumbs_Up_Comments:
			"RoiCalculator_Feedback_Thumbs_Up_Comments",
		RoiCalculator_Feedback_Thumbs_Down_Comments:
			"RoiCalculator_Feedback_Thumbs_Down_Comments",
		Mortgage_tab_feedback_sean_ellis: "Mortgage_tab_feedback_sean_ellis",
		Mortgage_tab_feedback_sean_ellis_comment:
			"Mortgage_tab_feedback_sean_ellis_comment",
		Crime_tab_feedback_sean_ellis: "Crime_tab_feedback_sean_ellis",
		Crime_tab_feedback_sean_ellis_comment:
			"Crime_tab_feedback_sean_ellis_comment",
		Permit_tab_feedback_sean_ellis: "Permit_tab_feedback_sean_ellis",
		Permit_tab_feedback_sean_ellis_comment:
			"Permit_tab_feedback_sean_ellis_comment",
		W2S_Insurance_Connect_Advisor_Clicked:
			"W2S_Insurance_Connect_Advisor_Clicked",
		W2S_Insurance_Connect_Advisor_Call_Me_Clicked:
			"W2S_Insurance_Connect_Advisor_Call_Me_Clicked",
		Bill_Reduction_Landing_Page_Viewed: "Bill_Reduction_Landing_Page_Viewed",
		Appeals_Landing_Page_Viewed: "Appeals_Landing_Page_Viewed",
		Overview_Rotating_Content_Clicked: "Overview_Rotating_Content_Clicked",
		Rotating_Content_Wildcard_Answer: "Rotating_Content_Wildcard_Answer",
		Expenses_Feedback_Sean_Ellis: "Expenses_Feedback_Sean_Ellis",
		Expenses_Feedback_Sean_Ellis_Comment:
			"Expenses_Feedback_Sean_Ellis_Comment",
		CAD_LP_COUNTY_VISITED: "CAD_LP_COUNTY_VISITED",
		CAD_LP_COUNTY_CTA: "CAD_LP_COUNTY_CTA",
		commercial_landing_page_view: "commercial_landing_page_view",
		tax_bill_management_landing_page_view:
			"tax_bill_management_landing_page_view",
		For_Investors_commercial_click: "For_Investors_commercial_click",
		For_Investors_SFR_click: "For_Investors_SFR_click",
		Investor_page_view: "Investor_page_view",
		Commercial_sign_up: "Commercial_sign_up",
		Commercial_contact_sales: "Commercial_contact_sales",
		Tax_bill_management_contact_sales: "Tax_bill_management_contact_sales",
		Tax_bill_management_submit_form: "Tax_bill_management_submit_form",
		Commercial_sales_form_submit: "Commercial_sales_form_submit",
		multiprop_sign_up_flow_start: "multiprop_sign_up_flow_start",
		multiprop_sign_up_continue_click: "multiprop_sign_up_continue_click",
		multiprop_sign_up_contact_page_view: "multiprop_sign_up_contact_page_view",
		multiprop_sign_up_contact_page_click:
			"multiprop_sign_up_contact_page_click",
		multiprop_sign_up_verify_page_view: "multiprop_sign_up_verify_page_view",
		multiprop_sign_up_verify_page_click: "multiprop_sign_up_verify_page_click",
		multiprop_sign_up_edit_details_verify_page_click:
			"multiprop_sign_up_edit_details_verify_page_click",
		multiprop_sign_up_edit_details_verify_page_save:
			"multiprop_sign_up_edit_details_verify_page_save",
		multiprop_sign_up_entity_page_view: "multiprop_sign_up_entity_page_view",
		multiprop_sign_up_entity_page_click: "multiprop_sign_up_entity_page_click",
		multiprop_sign_up_legal_page_view: "multiprop_sign_up_legal_page_view",
		multiprop_sign_up_legal_page_sign: "multiprop_sign_up_legal_page_sign",
		Mortgage_Refinance_Calculator_Results:
			"Mortgage_Refinance_Calculator_Results",
		Mortgage_Refinance_Calculator_View: "Mortgage_Refinance_Calculator_View",
		Refinance_Monitoring_Dont_Notify_Me_About_Savings:
			"Refinance_Monitoring_Dont_Notify_Me_About_Savings",
		Refinance_Monitoring_Start_From_Equity_Details:
			"Refinance_Monitoring_Start_From_Equity_Details",
		Refinance_Monitoring_Review_From_Equity_Details:
			"Refinance_Monitoring_Review_From_Equity_Details",
		Concierge_onboarding_upgrade_viewed: "Concierge_onboarding_upgrade_viewed",
		Concierge_upgrade_page_viewed: "Concierge_upgrade_page_viewed",
		Concierge_onboarding_upgrade_modal_action:
			"Concierge_onboarding_upgrade_modal_action",
		Concierge_onboarding_upgrade_payment_viewed:
			"Concierge_onboarding_upgrade_payment_viewed",
		Concierge_onboarding_upgrade_payment_action:
			"Concierge_onboarding_upgrade_payment_action",
		Concierge_upgrade_page_action: "Concierge_upgrade_page_action",
		Concierge_upgrade_payment_viewed: "Concierge_upgrade_payment_viewed",
		Concierge_upgrade_payment_action: "Concierge_upgrade_payment_action",
		Concierge_cancellation_after_payment_viewed:
			"Concierge_cancellation_after_payment_viewed",
		Concierge_cancellation_after_payment_action:
			"Concierge_cancellation_after_payment_action",
		Toggle_commercial: "Toggle_commercial",
		Toggle_residential: "Toggle_residential",
		toggle_singlefamilyrental: "toggle_singlefamilyrental",
		homepage_header_dropdowns_opens: "homepage_header_dropdowns_opens",
		homepage_header_nav_click: "homepage_header_nav_click",
		ASC_BANNER_SEEN: "ASC_BANNER_SEEN",
		ASC_BANNER_CLICKED: "ASC_BANNER_CLICKED",
		Trends_Exit_Intent_Viewed: "Trends_Exit_Intent_Viewed",
		Trends_Exit_Intent_Closed: "Trends_Exit_Intent_Closed",
		Trends_Exit_Intent_Address_CTA: "Trends_Exit_Intent_Address_CTA",
		Blogs_Exit_Intent_Address_CTA: "Blogs_Exit_Intent_Address_CTA",
		viewed_electric_savings_onboarding: "viewed_electric_savings_onboarding",
		cta_electric_savings_onboarding: "cta_electric_savings_onboarding",
	},
	EXPENSES_FEEDBACK_STORAGE_ID: "expenses-feedback",
	HOTJAR: {
		started_insurance_flow: "started_insurance_flow",
	},
	SHOW_MULTI_PROP_THRESHOLD: 5,
	REAUTHORIZATION_NEEDS_ATTENTION_REASONS,
	COMPANY_TYPE_OPTIONS: [
		"LLC",
		"Corporation",
		"Partnership",
		"Estate",
		"Trust",
		"Other",
	],
	EXEMPTIONS_AVERAGE_SAVINGS: "$762",
	EXEMPTIONS_TX_SAVINGS: "$1,973",
	EXEMPTIONS_GA_AVERAGE_FIRST_YEAR_SAVINGS: "$500",
	INSURANCE_POTENTIAL_SAVINGS: 362,
	EXEMPTIONS_CA_AVERAGE_FIVE_YEAR_SAVINGS: "$492",
	EXEMPTIONS_NY_AVERAGE_FIRST_YEAR_SAVINGS: "$800",
	EXEMPTIONS_IL_AVERAGE_FIRST_YEAR_SAVINGS: "$938",
	EXEMPTIONS_FL_AVERAGE_FIRST_YEAR_SAVINGS: "$743",
	EXEMPTIONS_MI_AVERAGE_FIRST_YEAR_SAVINGS: "$2,088",
	EXEMPTIONS_PA_AVERAGE_FIRST_YEAR_SAVINGS: "$529",
	MIN_APPEAL_SAVINGS: 75,
	EXEMPTIONS_FEE_PERCENTAGE: 25,
	PASSWORD_REGEX:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()])[A-Za-z\d@$!%*?&()]{8,}$/,
	COLORS: {
		terracotta: "#da845f",
		cloud: "#f7f7f0",
		azure: "#1C7CE5",
		powder: "#8CB3DC",
		denim_5: "#7F939D",
		denim: "#053249",
		sky_light: "#edf6ff",
	},
	STATES_THAT_ACCEPT_EXEMPTIONS_EVERYWHERE: ["TX", "CA", "NY"] as string[],
	EXEMPTION_ONLY_ARCHIVE_REASON: "NOT_ACCEPTING_APPEALS_YET",
	BILL_REDUCTION_AVG_SAVINGS: 239,
	MORTGAGE_EQUITY_AVG_SAVINGS: 439,
	SAVINGS_PAGE_ESTIMATES: {
		INSURANCE: 312,
		BILL_REDUCTION: 247,
	},
	GOOGLE_REVIEW_COUNT: "1,800",
} as const;

export const ENTITY_TOKEN_MAP: Record<string, boolean> = {
	ASSN: true,
	ASSOC: true,
	ASSOCIATION: true,
	BANK: true,
	COMPANY: true,
	CORP: true,
	CORPORATION: true,
	ENTERPRISES: true,
	EST: true,
	ESTATE: true,
	FINANCIAL: true,
	HOLDINGS: true,
	INC: true,
	INDUSTRIES: true,
	INTERNATIONAL: true,
	INVESTMENTS: true,
	LIMITED: true,
	LLC: true,
	LLP: true,
	LP: true,
	LTD: true,
	MANAGEMENT: true,
	PARTNERSHIP: true,
	PLC: true,
	REVOCABLE: true,
	TRUST: true,
	TRUSTEE: true,
	VENTURES: true,
};

export const WIM_TRACKING_CLICK_ID_KEY = "wim_click_id";

export const APPEAL_GENERIC_AVERAGE_SAVINGS = 1102;

export const APPEAL_GENERIC_AVERAGE_SAVINGS_FORMATTED = "$1,102";

export default constants;
